import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import '../App.css';

const Contact = () => {

  return (
    <ContactSection>
      <Helmet>
        <title>SCCANG Office - Contactos</title>
        <meta name="description" content="Modada: Rua do Horizonte 1549 RC, S. Romão do Coronado,
     4745-531 Trofa, Porto. Telefone: +351 229 864 330, Telemóvel: +351 932 025 698, email: sccang.office@gmail.com
     " />
        <meta name="keywords" content="sccang,contactos,telefone,telemovel,morada,email" />
      </Helmet>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <h2>Contacte-nos</h2>
        <Grid style={{ width: "100%", marginBottom: "2%" }} direction={"row"} container>

          <Grid size={12} sx={{ width: "100%" }}>
            <iframe title="sccang-address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.3237415310086!2d-8.559163291184605!3d41.28005810860999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24616b6edbaa9d%3A0xd0d5828aad8713ec!2sSCACF%20Studio!5e0!3m2!1sen!2spt!4v1610103582318!5m2!1sen!2spt"
              style={{ width: "100%", maxWidth: "1500px", height: "calc(80vh - 210px)", frameborder: "0", border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
          </Grid>

          <Grid className="spacer" size={12}></Grid>
          <Grid size={{ sm: 2, xs: 0 }} />
          <Grid size={{ sm: 4, xs: 12 }} className="left">
            <Typography variant="h6" >
              Rua do Horizonte 1549 RC
            </Typography>
            <Typography variant="body1" color="textSecondary">
              S. Romão do Coronado
            </Typography>
            <Typography variant="body1" color="textSecondary">
              4745-531
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Trofa
            </Typography>
          </Grid>
          <Grid size={{ sm: 1, xs: 0 }} sx={{ height: "1em", width: "100%" }} />
          <Grid size={{ sm: 5, xs: 12 }} >
            <div className="left">

              <Typography variant="h6" component="h5">
                Vias de contacto
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <PhoneIcon sx={{ color: "#3f51b5" }} style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="tel:+351229864330">+351 229 864 330</a>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <PhoneIcon sx={{ color: "#3f51b5" }} style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="tel:+351932025698">+351 932 025 698</a>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <EmailIcon sx={{ color: "#3f51b5" }} style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="mailto:sccang.office@gmail.com">sccang.office@gmail.com</a>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </ContactSection>
  );
};

export default Contact;

const ContactSection = styled.section`
  padding: 4rem 2rem;
  text-align: center;
  h2 {
    font-size: 2rem;
    color: #3f51b5;
    margin-bottom: 1.5rem;
  }
`;

// const ContactContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 2rem;
//   max-width: 600px;
//   margin: auto;
// `;

// const ContactInfo = styled.div`
//   h4 {
//     font-size: 1.25rem;
//     color: #343a40;
//     margin-bottom: 0.5rem;
//   }

//   p {
//     font-size: 1rem;
//     color: #6c757d;
//   }
// `;
