import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../images/sccang-office-white.svg';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function menuImage(value) {
  switch (value) {
    case 0:
      return <FormatListBulletedIcon />
    case 1:
      return <ContactPhoneIcon />
    case 2:
      return <InfoIcon />
    default:
      return <ReceiptLongRoundedIcon />
  }
}

const routes = [{ path: "/", title: "Início" }, { path: "/sobre", title: "Sobre Nós" }, { path: "/contactos", title: "Contactos" }, { path: "/orcamento", title: "Orçamento" }]

const Header = () => {
  const theme = useTheme()
  const isSmall = !useMediaQuery(theme.breakpoints.up("md"))
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    console.log(location.pathname)
    window.scrollTo(0, 0);
  }, [location.pathname])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const linkParser = (link, title) => {
    if (link === location.pathname) {
      return <b>{title}</b>
    }
    return title
  }

  return (
    <Nav>
      <NavContainer>
        <Logo>
          <Link to="/" style={{ height: 0, paddingTop: 0, position: "relative" }}> <img style={{ width: "200px", position: "absolute", left: 0, top: "-0.75em" }} src={logo} alt="SCCANG Office" /></Link>
        </Logo>

        {isSmall ? <IconButton onClick={handleClick}>
          <MenuRoundedIcon sx={{ color: "white" }} />
        </IconButton> : <NavLinks>
          {routes.map((route, index) => (
            <NavLink key={index} to={route.path}>{linkParser(route.path, route.title)}</NavLink>
          ))}
        </NavLinks>}
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {routes.map((route, index) => (
            <NavLink key={index} to={route.path}>
              <MenuItem selected={route.path === location.pathname} onClick={() => { handleClose() }}>
                <ListItemIcon>
                  {menuImage(index)}
                </ListItemIcon>
                {route.title}
              </MenuItem></NavLink>
          ))}
        </Menu>

      </NavContainer>
    </Nav>
  );
};

export default Header;


const Nav = styled.nav`
  background-color: #3f51b5;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;

  a {
    color: #fff;
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
`;

const NavLink = styled(Link)`
  font-size: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #adb5bd;
  }
`;
