// src/components/Hero.js
import React from 'react';
import styled from 'styled-components';
const Hero = () => {
  return (
    <HeroSection>
      <HeroContent>
        <h1>O Seu Aliado de Confiança em Gestão Contabilística</h1>
        <p>
        Na SCCANG Office oferecemos serviços de contabilidade completos e personalizados para as necessidades do seu negócio. Deixe os números connosco e concentre-se no crescimento da sua empresa.
        </p>
        <HeroButton href="#servicos">Os Nossos Serviços</HeroButton>
      </HeroContent>
    </HeroSection>
  );
};

export default Hero;

const HeroSection = styled.section`
  background-image: url('https://images.pexels.com/photos/209224/pexels-photo-209224.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(63, 81, 181, 0.7);
    background: radial-gradient(circle, rgba(63,81,181,0.8) 0%, rgba(63,81,181,0) 150%);
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  z-index: 2;
  width: 90%;
  max-width: 700px;
  margin: auto;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
`;

const HeroButton = styled.a`
  padding: 0.75rem 2rem;
  background-color: #fff;
  color: #3f51b5;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }
`;
