import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Grid2 as Grid, InputAdornment, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { formatPhoneNumber, getCountries, getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import '../App.css';

// import { sendEmail } from '../service/emailService';


const errorSx = { textAlign: "left", display: "block", marginBottom: "0.5em" }

function Budget(props) {
    const navigate = useNavigate()
    const theme = useTheme()
    const servicos = ["Contabilidade - Arquivo da documentação",
        "Contabilidade - Declarações de IRS e IRC",
        "Contabilidade - Declarações de IVA e IES",
        "Contabilidade - Dossier fiscal",
        "Contabilidade - Plano financeiro",
        "Contabilidade - Gestão de documentos contabilísticos",
        "Contabilidade - Gestão financeira e comercial",
        "Contabilidade - Processamento de ordenados",
        "Seguros",
        "Traduções",
        "Outro"]

    const frequencies = ["Único", "Continuado", "Mensal", "Anual", "Outro"]
    const contacts = ["Email", "Telefone"]
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const toContacts = () => {
        navigate("/contactos")
    }
    const toMain = () => {
        navigate("/")
    }

    const tiposCliente = ["Particular", "Empresa", "Profissional Liberal", "Nova empresa", "Outro"]
    const [otherService, setOtherService] = React.useState([false, ""]);
    const [frequency, setFrequency] = React.useState("");
    const [otherFrequency, setOtherFrequency] = React.useState([false, ""]);
    const [name, setName] = React.useState([false, ""]);
    const [tipoCliente, setTipoCliente] = React.useState(tiposCliente[0]);
    const [otherTipoCliente, setOtherTipoCliente] = React.useState([false, ""]);
    const [organization, setOrganization] = React.useState([false, ""]);
    const [numFunc, setNumFunc] = React.useState();
    const [volFat, setVolFat] = React.useState();
    const [sector, setSector] = React.useState([false, ""]);
    const [contact, setContact] = React.useState("Email");
    const [country, setCountry] = React.useState("PT")
    const [actualContact, setActualContact] = React.useState([false, "", true]);
    const [submitted, setSubmitted] = React.useState(false);
    const [notes, setNotes] = React.useState("");
    const [okModal, setOkModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [dataCheck, setDatacheck] = React.useState(false);

    const okFuntion = () => {
        setOkModal(false)
        toMain()
    }

    return (
        <BudgetSection>
            <h2>Pedir Orçamento</h2>
            <Helmet>
                <title>Solicitar Orçamento - SCCANG Office</title>
                <meta name="description" content="Serviços: Arquivo digital de documentação, Contabilidade Online, Declarações de IRS, IRC, IVA e IES, Dossier Fiscal Anual, Elaboração de plano financeiro
        Gestão de documentos contabilísticos, Gestão financeira e comercial, Pagamento de impostos e Recibos de vencimento, traduções, seguros" />
                <meta name="keywords" content="sccang,contabilidade,arquivo,arquivo digital,documentação,online,declaração,IRS,IRC,IVA,IES,dossier fiscal,plano financeiro,gestão,documentos contabilísticos,
        gestão financeira,gestão comercial,impostos,ordenados,vencimento,recibos,faturas,seguradora,traduções,seguros" />
            </Helmet>
            <div className="centered" style={{ width: "100%", height: "100%", paddingBottom: "10px" }}>
                <Paper style={{ width: "100%", maxWidth: "1300px", padding: "2em" }}>
                    <Grid container spacing={5} >
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Grid size={12} spacing={0}>
                                <h4>Detalhes de serviço e contacto</h4></Grid>
                            <Grid container size={12} direction={"column"} >
                                <Grid size={12} >
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Serviço:</Typography>
                                </Grid>
                                <Grid size={12} sx={{ position: "relative" }}>
                                    <Select id="service"
                                        autoWidth
                                        error={submitted && (props.selectedService === "" || props.selectedService == null)}
                                        style={{ width: props.selectedService === "Outro" ? "35%" : "100%" }}
                                        value={props.selectedService || ""}
                                        onChange={(e) => props.setSelectedService(e.target.value)}
                                    >
                                        {servicos.map(option => {
                                            return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{option}</MenuItem>)
                                        })}
                                    </Select>


                                    {props.selectedService === "Outro" ?
                                        <TextField
                                            value={otherService[1]}
                                            onBlur={() => { setOtherService([true, otherService[1].trim()]) }}
                                            onChange={(e) => { setOtherService([true, e.target.value]) }}
                                            error={otherService[1] === "" && otherService[0]}
                                            id="service"

                                            style={{ marginLeft: "2%", width: "63%" }}></TextField>
                                        : null}
                                    {submitted ? (
                                        (props.selectedService === "" || props.selectedService == null) ?
                                            <Typography color="error" variant="caption" sx={errorSx}>*Por favor selecione um serviço</Typography>

                                            : (
                                                props.selectedService === "Outro" && otherService[1] === "" && otherService[0] ?
                                                    <Typography color="error" variant="caption" sx={errorSx}>*Por favor explicite o serviço desejado</Typography>
                                                    :
                                                    <Typography color="error" variant="caption">&nbsp;</Typography>)
                                    )
                                        :
                                        <Typography color="error" variant="caption">&nbsp;</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container size={12} direction={"column"} >
                                <Grid >
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Frequência do serviço:</Typography>
                                </Grid>
                                <Grid size={12} >
                                    <Select id="frequency"
                                        autoWidth
                                        error={submitted && frequency === ""}
                                        style={{ width: frequency === "Outro" ? "35%" : "100%" }}
                                        value={frequency}
                                        onChange={(e) => setFrequency(e.target.value)}
                                    >
                                        {frequencies.map(option => {
                                            return (<MenuItem style={{ overflowX: "auto", width: "100%" }} key={option} value={option}>{option}</MenuItem>)
                                        })}
                                    </Select>
                                    {frequency === "Outro" ?
                                        frequency === "Outro" ?
                                            <TextField
                                                style={{ marginLeft: "2%", width: "63%" }}
                                                value={otherFrequency[1]}
                                                onBlur={() => { setOtherFrequency([true, otherFrequency[1].trim()]) }}
                                                onChange={(e) => { setOtherFrequency([true, e.target.value]) }}
                                                error={otherFrequency[1] === "" && otherFrequency[0]}
                                                id="frequency"
                                            ></TextField> : null
                                        : null}
                                    {submitted ? (
                                        frequency === "" ?
                                            <Typography color="error" variant="caption" sx={errorSx}>*Por favor selecione uma frequencia</Typography>
                                            :
                                            frequency === "Outro" && otherFrequency[1] === "" && otherFrequency[0] ?
                                                <Typography color="error" variant="caption" sx={errorSx}>*Por favor explicite a frequência desejada</Typography>
                                                : <Typography color="error" variant="caption">&nbsp;</Typography>
                                    )
                                        : <Typography color="error" variant="caption">&nbsp;</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container size={12} direction={"column"} >
                                <Grid size={12} >
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Pessoa de Contacto:</Typography>
                                </Grid>
                                <Grid size={12} >
                                    <TextField
                                        value={name[1]}
                                        onBlur={() => { setName([true, name[1].trim()]) }}
                                        onChange={(e) => { setName([true, e.target.value]) }}
                                        error={name[1] === "" && name[0]}
                                        id="name"
                                        name="name"
                                        autoComplete="name"
                                        style={{ width: "100%" }}
                                    />
                                    {submitted && name[1] === "" && name[0] ?
                                        <Typography className="left" color="error" variant="caption" sx={errorSx}>*Por favor indique o nome da pessoa a contactar</Typography> :
                                        <Typography color="error" variant="caption">&nbsp;</Typography>}
                                </Grid>

                            </Grid>
                            <Grid container size={12} direction={"column"}>
                                <Grid size={12}>
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Contacto:</Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Select id="contact"
                                        autoWidth
                                        style={{ width: "20%" }}
                                        value={contact}
                                        onChange={(e) => { setActualContact([false, "", true]); setContact(e.target.value) }}
                                    >
                                        {contacts.map(option => {
                                            return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{option}</MenuItem>)
                                        })}
                                    </Select>
                                    {contact === "Email" ?
                                        <React.Fragment>
                                            <TextField
                                                defaultValue={actualContact[1]}
                                                onBlur={() => { setActualContact([true, actualContact[1].trim(), !pattern.test(actualContact[1].trim())]) }}
                                                onChange={(e) => { setActualContact([true, e.target.value, false]) }}
                                                error={actualContact[2] && actualContact[0]}
                                                id="email"
                                                autoComplete="email"

                                                style={{ marginLeft: "2%", width: "78%" }}></TextField>
                                            {submitted && actualContact[2] && actualContact[0] ?
                                                <Typography style={{ marginLeft: "25%" }} color="error" variant="caption" sx={errorSx}>*Por favor indique o email</Typography>
                                                : <Typography color="error" variant="caption">&nbsp;</Typography>}
                                        </React.Fragment> :
                                        <React.Fragment>

                                            <Select
                                                id="country"
                                                autoComplete="country"
                                                autoWidth
                                                style={{ marginLeft: "2%", width: "32%" }}
                                                value={country}
                                                onChange={(e) => { setActualContact([true, "", false]); setCountry(e.target.value) }}
                                            >
                                                {getCountries().map(option => {
                                                    return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{en[option]}  +{getCountryCallingCode(option)}</MenuItem>)
                                                })}
                                            </Select>
                                            <TextField
                                                value={actualContact[1]}
                                                onChange={(e) => {
                                                    let phoneNumber = "+" + getCountryCallingCode(country) + e.target.value.trim()
                                                    if (isPossiblePhoneNumber(phoneNumber)) {
                                                        setActualContact([true, formatPhoneNumber(phoneNumber), false])
                                                    }
                                                    else
                                                        setActualContact([true, e.target.value.trim(), true])
                                                }}
                                                error={(actualContact[2] || actualContact[1] === "") && actualContact[0]}
                                                id="tel"
                                                autoComplete="tel"
                                                type="tel"
                                                style={{ marginLeft: "2%", width: "43%" }}></TextField>
                                            {submitted && (actualContact[2] || actualContact[1] === "") && actualContact[0] ?
                                                <Typography style={{ marginLeft: "57%" }} color="error" variant="caption" sx={errorSx}>*Por favor indique o número</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Grid size={12}>
                                <h4>Perfil do cliente</h4></Grid>
                            <Grid container size={12} direction={"column"}>
                                <Grid size={12} >
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Tipo de cliente:</Typography>
                                </Grid>

                                <Grid size={12} >
                                    <Select id="tipo-de-cliente"
                                        autoWidth
                                        style={{ width: tipoCliente === "Outro" ? "35%" : "100%" }}
                                        value={tipoCliente}
                                        error={submitted && tipoCliente === ""}
                                        onChange={(e) => setTipoCliente(e.target.value)}
                                    >
                                        {tiposCliente.map(option => {
                                            return (<MenuItem key={option} value={option}>{option}</MenuItem>)
                                        })}
                                    </Select>
                                    {tipoCliente === "Outro" ?
                                        <TextField
                                            value={otherTipoCliente[1]}
                                            onBlur={() => { setOtherTipoCliente([true, otherTipoCliente[1].trim()]) }}
                                            onChange={(e) => { setOtherTipoCliente([true, e.target.value]) }}
                                            error={otherTipoCliente[1] === "" && otherTipoCliente[0]}
                                            id="tipo-de-cliente"

                                            style={{ marginLeft: "2%", width: "63%" }}></TextField> : null}
                                    {submitted && tipoCliente === "Outro" && otherTipoCliente[1] === "" && otherTipoCliente[0] ?
                                        <Typography color="error" variant="caption" sx={errorSx}>*Por favor explicite a o tipo de organização</Typography>
                                        : <Typography color="error" variant="caption">&nbsp;</Typography>

                                    }

                                </Grid>

                            </Grid>

                            {["Particular", "Profissional Liberal"].includes(tipoCliente) ? null :
                                <Grid size={12} direction={"column"}>
                                    <Grid size={12} >
                                        <Typography className="left" variant="subtitle1" color="textSecondary">Nome da {tipoCliente !== "Outro" ? tipoCliente : "Organização"}:</Typography>
                                    </Grid>
                                    <Grid size={12} >
                                        <TextField
                                            value={organization[1]}
                                            onBlur={() => { setOrganization([true, organization[1].trim()]) }}
                                            onChange={(e) => { setOrganization([true, e.target.value]) }}
                                            error={organization[1] === "" && organization[0]}
                                            id="organization"
                                            autoComplete="organization"
                                            style={{ width: "100%" }}
                                        />

                                        {organization[1] === "" && organization[0] ?
                                            <Typography color="error" variant="caption" sx={errorSx}>*Por favor indique o nome da organização</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                                    </Grid>
                                </Grid>
                            }

                            <Grid container size={12} direction={"column"}>
                                <Grid size={12} >
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Setor de atividade:</Typography>
                                </Grid>
                                <Grid size={12} >
                                    <TextField
                                        value={sector[1]}
                                        onBlur={() => { setSector([true, sector[1].trim()]) }}
                                        onChange={(e) => { setSector([true, e.target.value]) }}
                                        error={sector[1] === "" && sector[0]}
                                        id="sector"
                                        style={{ width: "100%" }}
                                    />
                                    {submitted && sector[1] === "" && sector[0] ?
                                        <Typography color="error" variant="caption" sx={errorSx}>*Por favor indique o setor de atividade</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                                </Grid>
                            </Grid>

                            {props.selectedService === "Traduções" || ["Particular", "Profissional Liberal"].includes(tipoCliente) ?
                                <Grid size={12} /> : <React.Fragment>
                                    <Grid size={12} direction={"column"}>
                                        <Grid size={12} >
                                            <Typography className="left" variant="subtitle1" color="textSecondary">Número de funcionários:</Typography>
                                        </Grid>
                                        <Grid size={12} >
                                            <TextField
                                                error={submitted && isNaN(parseInt(numFunc))} style={{ width: "100%" }}
                                                defaultValue={null} onChange={(e) => { let nan = isNaN(parseInt(e.target.value.trim())); setNumFunc((nan || nan ? null : parseInt(e.target.value.trim()) < 0) ? 0 : parseInt(e.target.value.trim())) }} type="number" slotProps={{ htmlInput: { min: 1, step: 1 } }} />
                                            {submitted && isNaN(parseInt(numFunc)) ?
                                                <Typography color="error" variant="caption" sx={errorSx}>*Por favor indique o número de funcionários</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                                        </Grid>

                                    </Grid>


                                </React.Fragment>
                            }{props.selectedService !== "Traduções"&&<Grid size={12} direction={"column"}>
                                <Grid size={12} >
                                    <Typography className="left" variant="subtitle1" color="textSecondary">Volume de faturação:</Typography>
                                </Grid>
                                <Grid size={12} >
                                    <TextField style={{ width: "100%" }}
                                        id="volume-faturacao"
                                        error={submitted && isNaN(parseInt(volFat))}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                            },
                                            htmlInput: { min: 0, step: 1 }
                                        }}

                                        defaultValue={null} onChange={(e) => { let nan = isNaN(parseInt(e.target.value.trim())); setVolFat((nan || nan ? null : parseInt(e.target.value.trim()) < 0) ? 0 : parseInt(e.target.value.trim())) }} type="number" />
                                    {submitted && isNaN(parseInt(volFat)) ?
                                        <Typography color="error" variant="caption" sx={errorSx}>*Por favor indique o volume de faturação</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                                </Grid>

                            </Grid>}
                        </Grid>

                        <Grid size={12} container spacing={0}>
                            <Grid size={12} >
                                <Typography className="left" variant="subtitle1" color="textSecondary">Notas:</Typography>
                                <ScrollArea
                                    placeholder="Caso exista alguma incerteza quanto a alguma das respostas ou qualquer outra questão, pode fazê-la aqui."
                                    value={notes}
                                    onChange={(e) => {
                                        setNotes(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid size={12} >
                                <div style={{ display: "block", width: "100%" }}>
                                    <Typography color={submitted && !dataCheck ? "error" : "textPrimary"} variant="body2" className="left" style={{ float: "left", marginTop: "10px", display: "inline-block" }} onClick={() => setDatacheck(!dataCheck)}>  <Checkbox style={{ float: "left", marginTop: "-0.75em" }} onChange={(e) => setDatacheck(e.target.checked)} checked={dataCheck} /> Declaro que os meus dados podem ser utilizados para efeitos de comunicação e marketing da SCCANG Office.</Typography>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid size={12} container spacing={5}>

                            <Grid size={12} spacing={0}>
                                <Button
                                    onClick={() => {
                                        setSubmitted(true)


                                        let valid = dataCheck
                                        if (props.selectedService === "Outro") {
                                            setOtherService([true, otherService[1]])
                                            valid = valid && otherService[1] !== ""
                                        }

                                        valid = valid && props.selectedService !== ""


                                        if (frequency === "Outro") {
                                            setOtherFrequency([true, otherFrequency[1]])
                                            valid = valid && otherFrequency[1] !== ""
                                        }

                                        valid = valid && frequency !== ""

                                        setName([true, name[1]])
                                        valid = valid && name[1] !== ""


                                        if (tipoCliente === "Outro") {
                                            setOtherService([true, otherTipoCliente[1]])
                                            valid = valid && otherTipoCliente[1] !== ""
                                        }

                                        valid = valid && tipoCliente !== ""


                                        setOrganization([true, organization[1]])
                                        valid = valid && (tipoCliente === "Particular" || tipoCliente === "Outro" || organization[1] !== "")

                                        setSector([true, sector[1]])
                                        valid = valid && sector[1] !== ""

                                        setActualContact([true, actualContact[1], actualContact[2]])
                                        valid = valid && actualContact[1] !== "" && !actualContact[2]

                                        if (valid) {
                                            setShowBackdrop(true)
                                            sendEmail({
                                                name: name[1],
                                                client: tipoCliente === "Outro" ? "(Outro) " + otherTipoCliente[1] : tipoCliente,
                                                organization: organization[1],
                                                sector: sector[1],
                                                employees: numFunc == null ? "Não especificado" : numFunc,
                                                billing: volFat == null ? "Não especificado" : volFat,
                                                service: props.selectedService === "Outro" ? "(Outro) " + otherService[1] : props.selectedService,
                                                frequency: frequency === "Outro" ? "(Outro) " + otherFrequency[1] : frequency,
                                                contact: (contact === "Telefone" ? "(+" + getCountryCallingCode(country) + ") " : "") + actualContact[1],
                                                notes: notes,
                                            }, (e) => {
                                                setShowBackdrop(false);
                                                setOkModal(true)
                                            }, (e) => {
                                                setShowBackdrop(false)
                                                setErrorModal(true)
                                            })
                                        }
                                        else { console.log("yikes") }
                                    }} fullWidth variant="contained" >
                                    Solicitar Orçamento
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>


                    <div style={{ marginTop: "auto" }}></div>

                    <Dialog onClose={okFuntion} aria-labelledby="customized-dialog-title" open={okModal}>
                        <DialogContent  >
                            <Grid container spacing={1}>
                                <Grid size={2}>
                                    <CheckCircleIcon style={{ height: "100%", width: "100%", color: "#3f51b5" }} />
                                </Grid>
                                <Grid size={1}></Grid>
                                <Grid size={9}>
                                    <Typography variant="body1" style={{ height: "100%", marginTop: "1.25em" }} className="left">
                                        O seu pedido de orçamento foi registado. Será contactado brevemente através do {contact.toLowerCase()} disponibilizado.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={okFuntion} >
                                ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={() => setErrorModal(false)} open={errorModal}>
                        <DialogContent >
                            <Grid container spacing={1}>
                                <Grid size={2}>
                                    <ErrorIcon style={{ height: "100%", width: "100%" }} color="error" />
                                </Grid>
                                <Grid size={1}></Grid>
                                <Grid size={9}>
                                    <Typography variant="body1" style={{ height: "100%", marginTop: "1.25em" }} className="left">
                                        Algo inesperado aconteceu. Tente novamente mais tarde ou <Link className="link" onClick={toContacts} to="/contactos">contacte-nos</Link> diretamente.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setErrorModal(false)} >
                                ok
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Backdrop style={{
                        zIndex: theme.zIndex.drawer + 1,
                        color: '#fff',
                    }} open={showBackdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop></Paper>
            </div >
        </BudgetSection >
    );
}

function ScrollArea(props) {
    return <div className="ScrollArea" style={{ width: "100%" }}>
        <div className="ScrollContent">
            <textarea {...props}></textarea>
        </div>
    </div>
}

const BudgetSection = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: #3f51b5;
    margin-bottom: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #3f51b5;
  }

  
  button {
    background-color: #3f51b5;
  }
`;

export default Budget;


function sendEmail(body, afterResponse, onError) {
    const data = JSON.stringify(body)
    const options = {
        method: 'POST',
        headers: {
            'Access-Control-Request-Method': 'POST',
            'Access-Control-Request-Headers': 'Content-Type,Authorization',
            'Content-Type': 'application/json',
            'Content-Length': data.length
        },
        insecureHTTPParser: true,
        body: data

    }

    fetch("https://us-central1-sccang-office.cloudfunctions.net/app/api/sendEmail", options).then(res => {

        console.log(`statusCode: ${res.status}`)
        if (res.status !== 200) {
            onError()
            return
        }
        body = res.body
        afterResponse(body)
    }).catch(err => {
        onError()
    });

}