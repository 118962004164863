// src/components/data/servicesData.js
import { Box, Typography } from '@mui/material';
import {
  FaCalculator,
  FaFile,
  FaLanguage,
  FaMoneyBillWave
} from 'react-icons/fa';

import { FaFloppyDisk, FaUserShield } from 'react-icons/fa6';

import { IoBarChart, IoFileTrayFull, IoFileTrayStacked } from 'react-icons/io5';

export const accountingServices = [
  {
    icon: <FaFloppyDisk />,
    title: 'Arquivo da documentação',
    description: 'Armazenamento seguro dos documentos contabilísticos em formato físico, digital ou online.',
  },
  {
    icon: <IoFileTrayFull />,
    title: 'Gestão de documentos contabilísticos',
    description: 'Organização, lançamento e categorização dos documentos contabilísticos.',
  },
  {
    icon: <FaCalculator />,
    title: 'Gestão financeira e comercial',
    description: 'Controlo das operações comerciais para otimização dos resultados.',
  },
  {
    icon: <Box sx={{ position: "relative" }}>

      <Box sx={{ position: "absolute", top: "0.2em", right: "calc(50% - 15px)", zIndex: 1 }}><FaMoneyBillWave /></Box>
      <svg style={{ position: "absolute",zIndex:1, left: "50%", top: 0, transform: "translateX(-50%)" }} xmlns="http://www.w3.org/2000/svg"><polygon points="124,20 175,15 165,47 140,52 133,52 124,49" style={{ fill: "#f8f9fa", stroke: "#f8f9fa", strokeWidth: 1 }} /></svg>
      <Box style={{ zIndex: 10 }}><FaMoneyBillWave /></Box>
      <Box sx={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", zIndex: 1 }}><FaMoneyBillWave /></Box>
    </Box>,
    title: 'Processamento de ordenados',
    description: 'Cálculo dos salários e encargos do pessoal e emissão dos recibos de vencimento.',
  },
  {
    icon: <Box sx={{ position: "relative" }}><IoFileTrayStacked style={{ transform: "rotate(-90deg)" }} /><svg style={{ position: "absolute", left: "50%", top: 0, transform: "translateX(-50%)" }} xmlns="http://www.w3.org/2000/svg"><polygon points="130,20 140,15 140,51 130,49" style={{ fill: "#3f51b5", stroke: "#3f51b5", strokeWidth: 1 }} /></svg></Box>,
    title: 'Dossier fiscal',
    description: 'Preparação e organização do dossier fiscal anual.',
  },
  {
    icon: <IoBarChart />,
    title: 'Plano financeiro',
    description: 'Elaboração de um plano com estratégias para alcançar os objetivos da empresa.',
  },
  {
    icon: <Box sx={{ position: "relative" }}> <FaFile /> <Typography sx={{ position: "absolute", top: "2em", left: "50%", transform: "translateX(-50%)", lineHeight: "15px", color: "white" }} variant='caption'> <b>IRS<br />IRC</b></Typography></Box>,
    title: 'Declarações de IRS e IRC',
    description: 'Elaboração e envio das declarações de IRS e IRC.',
  },
  {
    icon: <Box sx={{ position: "relative" }}> <FaFile /> <Typography sx={{ position: "absolute", top: "2em", left: "50%", transform: "translateX(-50%)", lineHeight: "15px", color: "white" }} variant='caption'> <b>IVA<br />IES</b></Typography></Box>,
    title: 'Declarações de IVA e IES',
    description: 'Preparação e submissão das declarações de IVA e IES.',
  },
];

export const otherServices = [
  {
    icon: <FaUserShield />,
    title: 'Seguros',
    description: 'Gestão e aconselhamento sobre apólices de seguros para proteção empresarial.',
  },
  {
    icon: <FaLanguage />,
    title: 'Traduções',
    description: 'Serviço de tradução precisa e profissional entre Português e Inglês.',
  },
];
