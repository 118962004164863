import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import logo from '../images/sccang-office.svg';

const About = () => {
  return (
    <AboutSection>
      <Helmet>
        <title>SCCANG Office - Sobre nós</title>
        <meta name="description" content="A SCCANG Office é uma microempresa especializada em serviços de contabilidade e assessoria na gestão de negócios. Contamos com uma equipa de profissionais certificados e com mais de 30 anos de experiência, pronta para oferecer o suporte e aconselhamento de que necessita. Oferecemos assistência e orientação em diversas áreas, como processamento de salários, rendas, auditorias e seguros. Mantenha as suas finanças em ordem de forma eficiente, confiando-as aos especialistas do SCCANG Office. Assim, poderá direcionar a sua energia para o crescimento e desenvolvimento do seu negócio. O SCACF opera na região do Grande Porto, cumprindo rigorosamente as normas da DGS. O nosso escritório está disponível para atendimento presencial, mas também disponibilizamos aconselhamento remoto através de e-mail ou telefone, conforme a sua preferência." />
        <meta name="keywords" content="sccang,sobre,nós,espaço,instalações" />
      </Helmet>
      <h2>Sobre Nós</h2>
      <p style={{ textAlign: "justify" }}>
        A SCCANG Office é uma microempresa especializada em serviços de contabilidade e assessoria na gestão de negócios. Contamos com uma equipa de profissionais certificados e com mais de 30 anos de experiência, pronta para oferecer o suporte e aconselhamento de que necessita.
        <br /> <br />
        Oferecemos assistência e orientação em diversas áreas, como processamento de salários, rendas, auditorias e seguros. Mantenha as suas finanças em ordem de forma eficiente, confiando-as aos especialistas da SCCANG Office. Assim, poderá direcionar a sua energia para o crescimento e desenvolvimento do seu negócio.
        <br /> <br />
        A SCCANG Office opera na região do Grande Porto, cumprindo rigorosamente as normas da DGS. O nosso escritório está disponível para atendimento presencial, mas também disponibilizamos aconselhamento remoto através de e-mail ou telefone, conforme a sua preferência.
      </p>
      <img src={logo} alt="SCCANG Office" style={{ maxWidth: "750px", width: "100%", marginTop: "3em" }} />
    </AboutSection>
  );
};

export default About;

const AboutSection = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: #3f51b5;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #6c757d;
    max-width: 800px;
    margin: auto;
    line-height: 1.6;
  }
`;
