// src/components/Services.js
import React from 'react';
import styled from 'styled-components';
import { accountingServices, otherServices } from '../components/data/servicesData';
import {  useNavigate } from 'react-router-dom';
const Services = (props) => {
  const navigate=useNavigate();
  const selectService=(service,accounting=false)=>{
      props.setSelectedService((accounting?"Contabilidade - ":"")+service.title);
      navigate('/orcamento');
  }
  return (
    <ServicesSection id="servicos">
      <ServicesContainer>
        <SectionTitle>Serviços de Contabilidade</SectionTitle>
        <ServicesGrid>
          {accountingServices.map((service, index) => (
            <ServiceCard key={index} onClick={()=>{selectService(service,true)}}>
              <IconWrapper>{service.icon}</IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </ServicesGrid>

        <SectionTitle>Outros Serviços</SectionTitle>

        <ServicesGrid >
          {otherServices.map((service, index) => (
            <ServiceCard key={index} onClick={()=>{selectService(service)}}>
              <IconWrapper>{service.icon}</IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </ServicesContainer>
    </ServicesSection>
  );
};

export default Services;

const ServicesSection = styled.section`
  padding: 4rem 0;
  background-color: #fff;
`;

const ServicesContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #3f51b5;
  text-align: center;
  margin-bottom: 3rem;
`;

const ServicesGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 4rem;
  alignItems: center;
  justify-content: center;
`;

const ServiceCard = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  text-decoration: none; 
  

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer
  };
  
  &:active {
    background-color: rgba(0,0,0,0.1);
    
  }
`;


const IconWrapper = styled.div`
  font-size: 3rem;
  color: #3f51b5;
  margin-bottom: 1rem;
`;

const ServiceTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #343a40;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;
