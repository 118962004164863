import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterSection>
      <FooterContainer>
        <FooterAbout>
          <h3>SCCANG Office</h3>
          <p style={{ textAlign: "justify" }}>

            Conte com o suporte e aconselhamento de especialistas certificados, com mais de 30 anos de experiência no setor.
            <br /><br />
            Garanta a gestão eficiente e precisa das suas contas, confiando as suas finanças aos profissionais da SCCANG Office. Assim, poderá concentrar-se plenamente no crescimento e sucesso do seu negócio.
          </p>
        </FooterAbout>
        <FooterContact>
          <h4>Contacte-nos</h4>
          <ContactItem>
            <Typography style={{ color: "white", marginRight: "1em" }} variant="body1" >
              <PhoneIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a href="tel:+351229864330">+351 229 864 330</a>
            </Typography>
            <Typography style={{ color: "white" }} variant="body1" >
              <PhoneIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a href="tel:+351932025698">+351 932 025 698</a>
            </Typography>
          </ContactItem>
          <ContactItem>
            <Typography style={{ color: "white" }} variant="body1" >
              <EmailIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a href="mailto:sccang.office@gmail.com">sccang.office@gmail.com</a>
            </Typography>
          </ContactItem>
          <ContactItem>
            <Typography style={{ color: "white", marginBottom: "1em" }} variant="body1" >
              <LocationOnIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a href="https://goo.gl/maps/nKpzCm8XAhrCTzsq5"  rel="noreferrer" target="_blank">Rua do Horizonte 1549 RC, 4745-531 Trofa</a>
            </Typography>
          </ContactItem>
          <iframe title="sccang-address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.3237415310086!2d-8.559163291184605!3d41.28005810860999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24616b6edbaa9d%3A0xd0d5828aad8713ec!2sSCACF%20Studio!5e0!3m2!1sen!2spt!4v1610103582318!5m2!1sen!2spt"
            style={{ width: "100%", maxWidth: "400px", height: "10em", frameborder: "0", border: 0, }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>

        </FooterContact>

      </FooterContainer>
      <FooterBottom>
        <p>&copy; {new Date().getFullYear()} SCCANG OFFICE. <br />Todos os direitos reservados.</p>
      </FooterBottom>
    </FooterSection>
  );
};

export default Footer;

const FooterSection = styled.footer`
  background-color: #3f51b5;
  color: #fff;
  padding: 4rem 0 2rem 0;
`;

const FooterContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
`;

const FooterAbout = styled.div`
  flex: 1 1 300px;
  margin-right: 2em;
  h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6;
  }
`;

const FooterContact = styled.div`
  flex: 1 1 350px;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  span {
    font-size: 1rem;
  }
`;


const FooterBottom = styled.div`
  text-align: center;
  margin-top: 2rem;

  p {
    font-size: 0.875rem;
  }
`;
