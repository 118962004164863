import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/shared/Footer';
import GlobalStyles from './components/shared/GlobalStyles';
import Header from './components/shared/Header';
import About from './pages/About';
import Budget from './pages/Budget';
import Contact from './pages/Contact';
import Home from './pages/Home';

function App() {
  const [selectedService, setSelectedService] = React.useState(null);
  return (
    <HelmetProvider>
      <Router>
        <GlobalStyles />
        <Header />
        <Routes>
          <Route path="/" element={<Home selectedService={selectedService} setSelectedService={setSelectedService} />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/contactos" element={<Contact />} />
          <Route path="/orcamento" element={<Budget selectedService={selectedService} setSelectedService={setSelectedService} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
