import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import Services from './Services';


const Home = (props) => {
  return (
    <>
      <Helmet>
        <title>SCCANG Office - Início</title>
        <meta name="description" content="Serviços: Arquivo digital de documentação, Contabilidade Online, Declarações de IRS, IRC, IVA e IES, Dossier Fiscal Anual, Elaboração de plano financeiro
        Gestão de documentos contabilísticos, Gestão financeira e comercial, Pagamento de impostos, Recibos de vencimento, seguros, traduções. " />
        <meta name="keywords" content="sccang, contabilidade,arquivo,arquivo digital,documentação,online,declaração,IRS,IRC,IVA,IES,dossier fiscal,plano financeiro,gestão,documentos contabilísticos,
        gestão financeira,gestão comercial,impostos,ordenados,vencimento,recibos,faturas,seguradora,seguro,traduções" />
      </Helmet>
      <Hero />
      <Services {...props} />
    </>
  );
};

export default Home;
